<template>
    <div>
        <div id="container">
            <!-- staff card -->
            <b-card
                class="border border-primary"
                v-if="details.user === 'staffs'"
            >
                <!-- staff details -->
                <div class="row">
                    <div class="col-md-4">
                        <div class="profile_section">
                            <img
                                src="../../../assets/images/users/profilepic.png"
                                alt=""
                                v-if="!details.profile_pic_url"
                                class="profile_pic"
                            />
                            <h5>{{ details.name }}</h5>
                            <p>{{ details.username }}</p>
                            <p>{{ details.email }}</p>
                            <p v-if="details.mobile">{{ details.mobile }}</p>

                            <p>
                                <span
                                    v-for="stat in status"
                                    :key="stat.id"
                                    class="card_stats"
                                >
                                    <div
                                        v-if="stat.id === details.status"
                                        class="status_row"
                                    >
                                        <p>Status : &nbsp;&nbsp;</p>
                                        <div :class="stat.text"></div>
                                        <p>&nbsp;&nbsp;{{ stat.text }}</p>
                                    </div>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <form
                            class="form-horizontal"
                            @submit.prevent="updateStaff"
                        >
                            <div class="form-group">
                                <label for="ownerfname">Name*</label>
                                <input
                                    type="text"
                                    v-model="payload.name"
                                    class="form-control mb-3"
                                    id="username"
                                />
                            </div>

                            <div class="form-group">
                                <label for="ownerfname">Email*</label>
                                <input
                                    type="text"
                                    v-model="payload.email"
                                    class="form-control mb-3"
                                    id="username"
                                    disabled
                                />
                            </div>

                            <div class="form-group">
                                <label for="ownerfname">Groups</label>
                                <b-form-select
                                    id="ownerfname"
                                    name="ownerfname"
                                    v-model="payload.group"
                                    :options="mappedGroups"
                                    disabled
                                ></b-form-select>
                            </div>
                            <div class="form-group">
                                <label for="ownerfname">Status</label>
                                <b-form-radio-group
                                    v-model="payload.status"
                                    :options="accountStatus"
                                    class="mb-3"
                                    value-field="item"
                                    text-field="name"
                                    disabled-field="notEnabled"
                                ></b-form-radio-group>
                            </div>
                            <submitButton />
                        </form>
                    </div>
                </div>

                <b-button pill variant="primary" @click="close" id="x"
                    >X</b-button
                >
            </b-card>

            <!-- owners and tenants -->
            <b-card
                class="border border-primary"
                v-if="details.user === 'owners' || details.user === 'tenants'"
            >
                <div class="row">
                    <div class="col-md-4">
                        <div class="profile_section">
                            <img
                                src="../../../assets/images/users/profilepic.png"
                                alt=""
                                v-if="!details.profile_pic_url"
                                class="profile_pic"
                            />
                            <img
                                v-if="details.profile_pic_url"
                                class="profile_pic"
                                :src="`data:image/png;base64,${profilePic}`"
                                alt="Profile Picture"
                            />
                            <h4>{{ details.name }}</h4>
                            <p>{{ details.username }}</p>
                            <p>{{ details.email }}</p>
                            <p v-if="details.mobile">{{ details.mobile }}</p>
                            <p
                                v-for="stat in status"
                                :key="stat.id"
                                class="card_stats"
                            >
                                <span
                                    v-if="stat.id === details.status"
                                    class="status_row"
                                >
                                    <p>Status : &nbsp;&nbsp;</p>
                                    <div :class="stat.text"></div>
                                    <p>&nbsp;&nbsp;{{ stat.text }}</p>
                                </span>
                            </p>
                        </div>
                    </div>

                    <div class="col-md-8">
                        <h4>Documents</h4>
                        <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <b-button
                                    variant="primary"
                                    @click="viewDocument(visa)"
                                    :disabled="!visa"
                                >
                                    Visa</b-button
                                >
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <b-button
                                    variant="primary"
                                    @click="viewDocument(passport)"
                                    :disabled="!passport"
                                    >Passport</b-button
                                >
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <b-button
                                    variant="primary"
                                    @click="viewDocument(eid)"
                                    :disabled="!eid"
                                    >Emirates Id</b-button
                                >
                            </div>
                        </div>
                        <br />
                        <h4>Units</h4>
                        <div class="row">
                            <div class="col-lg-12">
                                <b-table
                                    :items="units"
                                    responsive="true"
                                    :fields="fields"
                                    sort-icon-left
                                >
                                    <template #cell(payment_type)="data">
                                        <div
                                            v-for="request in paymentTypes"
                                            :key="request.id"
                                        >
                                            <p
                                                v-if="
                                                    data.item.payment_type ===
                                                    request.id
                                                "
                                            >
                                                {{ request.type }}
                                            </p>
                                        </div>
                                    </template>
                                    <!-- <template v-slot:cell(action)="data">
                                        <b-button
                                            variant="primary"
                                            @click="view(data)"
                                            >Deactivate</b-button
                                        >
                                    </template> -->
                                </b-table>
                            </div>
                        </div>
                    </div>
                </div>

                <b-button pill variant="primary" @click="close" id="x">
                    X</b-button
                >

                <div>
                    <b-form inline @submit.prevent="updateOwner">
                        <label
                            class="mr-sm-2"
                            for="inline-form-custom-select-pref"
                            >Status:</label
                        >
                        <b-form-radio-group
                            v-model="payload.status"
                            :options="accountStatus"
                            id="inline-form-custom-select-pref"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                        ></b-form-radio-group>
                        <b-button variant="primary" type="submit"
                            >Update</b-button
                        >
                    </b-form>
                </div>
            </b-card>
        </div>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { BASE_URL, USER_STATUS, PAYMENT_TYPES } from "../../common";
import axios from "axios";
import submitButton from "../../../components/submitButton.vue";
import alertMixin from "../../../mixins/alertMixin";
import unitMixin from "../../../mixins/unitMixin";

export default {
    mixins: [alertMixin, unitMixin],
    components: {
        submitButton,
    },
    props: {
        details: Object,
    },
    data() {
        return {
            paymentTypes: PAYMENT_TYPES,
            profilePic: null,
            units: this.details.unit_info,
            passport: null,
            visa: null,
            eid: null,
            // cols: [
            //     { key: "unit_id", sortable: true },
            //     { key: "unit_no", sortable: true },
            //     { key: "payment_type", sortable: true },
            //     "Action",
            // ],

            accountStatus: [
                { item: "0", name: "Inactive" },
                { item: "1", name: "Active" },
                { item: "2", name: "Banned" },
            ],
            groups: [],
            status: USER_STATUS,
            payload: {
                email: this.details.email,
                group: this.details.user,
                id: this.details.id,
                mobile: this.details.mobile,
                name: this.details.name,
                status: this.details.status,
            },
        };
    },
    mounted() {
        if (this.unitCount > 1) {
            console.log("the user has multiple units assigned");
        }
        if (this.details.user === "staffs") {
            this.getGroups();
        }
        if (this.details.user === "owners" || this.details.user === "tenants") {
            console.log(this.details.id);
            this.fetchOwnerInformation(this.details.id, this.details.user);
        }
        if (this.details.profile_pic_url) {
            this.fetchProfilePic(this.details.profile_pic_url);
        }
    },
    computed: {
        fields() {
            let result = [];
            if (this.details.user === "owners") {
                result = [
                    { key: "unit_id", sortable: true },
                    { key: "unit_no", sortable: true },
                    { key: "payment_type", sortable: true },
                    // "Action",
                ];
            }
            if (this.details.user === "tenants") {
                result = [
                    { key: "unit_id", sortable: true },
                    { key: "unit_no", sortable: true },
                    // "Action",
                ];
            }
            return result;
        },
        mappedGroups() {
            return this.groups.map((ele) => {
                return {
                    value: ele.id,
                    text: ele.name,
                };
            });
        },
        unitCount() {
            return this.units.length;
        },
    },
    methods: {
        fetchProfilePic(url) {
            axios
                .get(`${BASE_URL}${url}/?token=${localStorage.cs_user_token}`)
                .then((res) => {
                    this.profilePic = res.data.file;
                });
        },

        fetchOwnerInformation(id, role) {
            axios
                .get(
                    `${BASE_URL}${role}/${id}/?token=${localStorage.cs_user_token}`
                )
                .then((res) => {
                    console.log(res.data);
                    if (role === "owners") {
                        this.passport = res.data.owner.passport;
                        this.visa = res.data.owner.visa;
                        this.eid = res.data.owner.eid;
                    } else if (role === "tenants") {
                        this.passport = res.data.tenant.passport;
                        this.visa = res.data.tenant.visa;
                        this.eid = res.data.tenant.eid;
                    }
                });
        },

        close() {
            this.$emit("close", false);
        },

        updateStaff() {
            if (localStorage.cs_user_token) {
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                });
                let payload = {
                    id: this.details.id,
                    group: "staffs",
                    name: this.payload.name,
                    status: this.payload.status,
                };
                axios
                    .post(
                        `${BASE_URL}staffs/update?token=${localStorage.cs_user_token}`,
                        payload
                    )
                    .then((res) => {
                        console.log(res);
                        loader.hide();
                        this.successPopUp("Successfully updated staff details");
                        this.$store.dispatch(
                            "apidata/fetchAllStaffs",
                            localStorage.cs_user_token
                        );
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch((err) => {
                        loader.hide();
                    });
            }
        },
        updateOwner() {
            let role = this.details.user;
            let payload = {};
            if (role === "owners") {
                payload.email = this.details.email;
                payload.group = this.details.user;
                payload.id = this.details.id;
                payload.mobile = this.details.mobile;
                payload.name = this.details.name;
                payload.status = this.payload.status;
            } else if (role === "tenants") {
                payload.group = this.details.user;
                payload.id = this.details.id;
                payload.name = this.details.name;
                payload.status = this.payload.status;
            }
            if (localStorage.cs_user_token) {
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                });

                axios
                    .post(
                        `${BASE_URL}${role}/update?token=${localStorage.cs_user_token}`,
                        payload
                    )
                    .then((res) => {
                        console.log(res);
                        loader.hide();
                        this.successPopUp("Successfully updated user status");
                        if (role === "owners") {
                            this.$store.dispatch(
                                "apidata/fetchAllOwners",
                                localStorage.cs_user_token
                            );
                        } else if (role === "tenants") {
                            this.$store.dispatch(
                                "apidata/fetchAllTenants",
                                localStorage.cs_user_token
                            );
                        }
                        this.close();
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch((err) => {
                        loader.hide();
                    });
            }
        },
        getGroups() {
            axios
                .get(`${BASE_URL}groups/?token=${localStorage.cs_user_token}`)
                .then((res) => {
                    this.groups = res.data.response;
                    res.data.response.map((e) => {
                        if (e.id === this.details.group_id) {
                            this.payload.group = e.id;
                        }
                    });
                });
        },
    },
};
</script>
<style scoped>
.a {
    height: 20px;
    width: 20px;
    background-color: red;
}
.profile_section {
    max-width: 200px;
    margin: auto;
    text-align: center;
}
.profile_pic {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 1px solid #c59220;
}
#container {
    position: relative;
}

#x {
    position: absolute;
    background: #c59220;
    color: white;
    top: -10px;
    right: -10px;
}
</style>
