<template>
    <div>
        <UserDetails
            v-if="showDetails"
            @close="showDetails = !showDetails"
            :details="details"
        />
        <b-overlay :show="allStaffs.length === 0" rounded="sm">
            <div class="row" v-if="!showDetails">
                <div class="col-12">
                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-6">
                            <div
                                id="tickets-table_length"
                                class="dataTables_length"
                            >
                                <label class="d-inline-flex align-items-center">
                                    Show&nbsp;
                                    <b-form-select
                                        v-model="perPage"
                                        size="sm"
                                        :options="pageOptions"
                                    ></b-form-select
                                    >&nbsp;staffs
                                </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div
                                id="tickets-table_filter"
                                class="dataTables_filter text-md-right"
                            >
                                <label class="d-inline-flex align-items-center">
                                    Search:
                                    <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Search Staffs..."
                                        class="form-control form-control-sm ml-2"
                                    ></b-form-input>
                                </label>
                            </div>
                        </div>
                        <!-- End search -->
                    </div>
                    <!-- Table -->

                    <div class="table-responsive mb-0">
                        <b-table
                            :items="allStaffs"
                            :fields="fields"
                            responsive="sm"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            sort-icon-left
                            :filter-included-fields="filterOn"
                            thead-class="d-none"
                            tbody-class="card_table"
                        >
                            <template #cell()="data">
                                <b-card
                                    v-if="data"
                                    style="width: 15rem"
                                    class="border border-primary"
                                    id="staff_card"
                                    :img-src="
                                        require('@/assets/images/users/profilepic.png')
                                    "
                                    img-alt="Card image"
                                    img-top
                                    @click="viewDetails(data)"
                                >
                                    <b-card-text>
                                        <h6>{{ data.item.name }}</h6>
                                        <span
                                            v-for="group in groups"
                                            :key="group.id"
                                        >
                                            <p
                                                v-if="
                                                    group.id ===
                                                    data.item.group_id
                                                "
                                            >
                                                {{ group.name }}
                                            </p>
                                        </span>

                                        <p>{{ data.item.email }}</p>
                                        <p v-if="data.item.mobile">
                                            {{ data.item.mobile }}
                                        </p>
                                    </b-card-text>
                                    <div
                                        v-for="stat in status"
                                        :key="stat.id"
                                        class="card_stats"
                                    >
                                        <div
                                            v-if="stat.id === data.item.status"
                                            class="status_row"
                                        >
                                            <p>Status : &nbsp;&nbsp;</p>
                                            <div :class="stat.text"></div>
                                            <p>&nbsp;&nbsp;{{ stat.text }}</p>
                                        </div>
                                    </div>
                                </b-card>
                            </template>
                        </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div
                                class="dataTables_paginate paging_simple_numbers float-right"
                            >
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"
                                    ></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import { BASE_URL, USER_STATUS } from "../../common";
import axios from "axios";
import UserDetails from "./userDetails.vue";
export default {
    components: {
        UserDetails,
    },
    props: ["show"],
    data() {
        return {
            details: {},
            showDetails: this.show,
            showSpinner: true,
            groups: [],
            status: USER_STATUS,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,

            fields: [{ key: "id", sortable: true }],
        };
    },
    mounted() {
        this.getGroups();
    },
    methods: {
        getGroups() {
            axios
                .get(`${BASE_URL}groups/?token=${localStorage.cs_user_token}`)
                .then((res) => {
                    this.groups = res.data.response;
                });
        },
        viewDetails(data) {
            if (localStorage.cs_user_role === "Administrator") {
                this.details = data.item;
                this.details.user = "staffs";
                console.log("parent", data.item);
                this.showDetails = true;
            }
        },
    },
    computed: {
        allStaffs() {
            return this.$store.getters["apidata/getAllStaffs"];
        },

        rows() {
            return this.allStaffs.length;
        },
    },
};
</script>
<style scoped>
.status_row {
    display: flex;
    align-items: baseline;
}

.Active {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: green;
}
.Inactive {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #c59220;
}
.Banned {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: red;
}
</style>
