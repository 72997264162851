<template>
    <div>
        <b-overlay :show="allTenants.length === 0" rounded="sm">
            <UserDetails
                v-if="showDetails"
                @close="showDetails = !showDetails"
                :details="details"
            />
            <div class="row" v-if="!showDetails">
                <div class="col-12">
                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-6">
                            <div
                                id="tickets-table_length"
                                class="dataTables_length"
                            >
                                <label class="d-inline-flex align-items-center">
                                    Show&nbsp;
                                    <b-form-select
                                        v-model="perPage"
                                        size="sm"
                                        :options="pageOptions"
                                    ></b-form-select
                                    >&nbsp;tenants
                                </label>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6">
                            <div
                                id="tickets-table_filter"
                                class="dataTables_filter text-md-right"
                            >
                                <label class="d-inline-flex align-items-center">
                                    Search:
                                    <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Search Tenants..."
                                        class="form-control form-control-sm ml-2"
                                    ></b-form-input>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive mb-0">
                        <b-table
                            :items="allTenants"
                            :fields="fields"
                            responsive="sm"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            sort-icon-left
                            :filter-included-fields="filterOn"
                            thead-class="d-none"
                            tbody-class="card_table"
                        >
                            <template #cell()="data">
                                <b-card
                                    v-if="data"
                                    style="width: 15rem"
                                    class="border border-primary"
                                    id="staff_card"
                                    :img-src="
                                        require('@/assets/images/users/profilepic.png')
                                    "
                                    img-alt="Card image"
                                    img-top
                                    @click="viewDetails(data)"
                                >
                                    <b-card-text>
                                        <h6>{{ data.item.name }}</h6>
                                        <p>{{ data.item.username }}</p>
                                        <p>{{ data.item.email }}</p>
                                        <p v-if="data.item.mobile">
                                            {{ data.item.mobile }}
                                        </p>
                                    </b-card-text>
                                    <div
                                        v-for="stat in status"
                                        :key="stat.id"
                                        class="card_stats"
                                    >
                                        <div
                                            v-if="stat.id === data.item.status"
                                            class="status_row"
                                        >
                                            <p>Status : &nbsp;&nbsp;</p>
                                            <div :class="stat.text"></div>
                                            <p>&nbsp;&nbsp;{{ stat.text }}</p>
                                        </div>
                                    </div>
                                </b-card>
                            </template>
                        </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div
                                class="dataTables_paginate paging_simple_numbers float-right"
                            >
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"
                                    ></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import { USER_STATUS } from "../../common";
import UserDetails from "./userDetails.vue";

// import axios from "axios";

export default {
    components: {
        UserDetails,
    },
    props: ["show"],
    data() {
        return {
            details: {},
            groups: [],
            status: USER_STATUS,
            showDetails: this.show,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [{ key: "id", sortable: true }],
        };
    },
    mounted() {},
    methods: {
        viewDetails(data) {
            if (
                localStorage.cs_user_role === "Administrator" ||
                localStorage.cs_user_role === "Archiving"
            ) {
                console.log(data.item);
                this.details = data.item;
                this.details.user = "tenants";
                this.showDetails = true;
            }
        },
    },
    computed: {
        allTenants() {
            return this.$store.getters["apidata/getAllTenants"];
        },

        rows() {
            return this.allTenants.length;
        },
    },
};
</script>
<style>
#staff_card:hover {
    cursor: pointer;
}
.status_row {
    display: flex;
    align-items: baseline;
}

.Active {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: green;
}
.Inactive {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #c59220;
}
.Banned {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: red;
}
.dashboard_card--icon {
    height: 50px;
    width: 40px;
    object-fit: contain;
}
.dashboard_card--text {
    padding: 10px;
    margin: 10px;
    font-size: 1.5rem;
}
.card_first {
    -webkit-transform: perspective(600) rotateY(18deg);
    -moz-transform: perspective(600) rotateY(18deg);
    -ms-transform: perspective(600) rotateY(18deg);
    -o-transform: perspective(600) rotateY(18deg);
}
.card_last {
    -webkit-transform: perspective(600) rotateY(-18deg);
    -moz-transform: perspective(600) rotateY(-18deg);
    -ms-transform: perspective(600) rotateY(-18deg);
    -o-transform: perspective(600) rotateY(-18deg);
}
.card-img,
.card-img-top {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    margin: auto;
    margin-top: 25px;
    border: 1px solid #c59220;
}
.card-text {
    margin: auto;
    text-align: center;
}
.card_table {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
</style>
