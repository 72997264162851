<template>
    <Layout>
        <div class="row">
            <div class="col-lg-3">
                <b-card
                    header-class="bg-transparent border-primary"
                    class="border border-primary card_first"
                    @click="show('STAFF')"
                >
                    <template v-slot:header>
                        <h5 class="my-0 text-primary">Number Of Staffs</h5>
                    </template>
                    <h2 class="card-title mt-0">
                        <img
                            class="dashboard_card--icon"
                            src="../../../assets/images/dashboard/Icon metro-users@2x.png"
                            alt=""
                        />
                        <span class="dashboard_card--text">
                            {{ stats.staffs }}</span
                        >
                    </h2>
                </b-card>
            </div>
            <div class="col-lg-3">
                <b-card
                    header-class="bg-transparent border-primary"
                    class="border border-primary"
                    @click="show('OWNER')"
                >
                    <template v-slot:header>
                        <h5 class="my-0 text-primary">Number Of Owners</h5>
                    </template>
                    <h2 class="card-title mt-0">
                        <img
                            class="dashboard_card--icon"
                            src="../../../assets/images/dashboard/Icon metro-users@2x.png"
                            alt=""
                        />
                        <span class="dashboard_card--text">
                            {{ stats.owners }}</span
                        >
                    </h2>
                </b-card>
            </div>

            <div class="col-lg-3">
                <b-card
                    header-class="bg-transparent border-primary"
                    class="border border-primary card_last"
                    @click="show('TENANT')"
                >
                    <template v-slot:header>
                        <h5 class="my-0 text-primary">Number Of Tenants</h5>
                    </template>
                    <h2 class="card-title mt-0">
                        <img
                            class="dashboard_card--icon"
                            src="../../../assets/images/dashboard/Icon metro-users@2x.png"
                            alt=""
                        />
                        <span class="dashboard_card--text">
                            {{ stats.tenants }}</span
                        >
                    </h2>
                </b-card>
            </div>
        </div>

        <StaffList v-if="users === 'STAFF'" :show="showDetails" />
        <OwnersList v-if="users === 'OWNER'" :show="showDetails" />
        <TenantList v-if="users === 'TENANT'" :show="showDetails" />
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
// import { BASE_URL, } from "../../common";
// import axios from "axios";
import OwnersList from "./ownersList.vue";
import StaffList from "./staffList.vue";
import TenantList from "./tenantList.vue";

export default {
    components: {
        Layout,
        OwnersList,
        StaffList,
        TenantList,
    },
    data() {
        return {
            showDetails: false,
            users: "STAFF",
            groups: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [{ key: "id", sortable: true }],
        };
    },
    mounted() {
        this.$store.dispatch(
            "apidata/fetchAllStaffs",
            localStorage.cs_user_token
        );

        this.$store.dispatch(
            "apidata/fetchAllOwners",
            localStorage.cs_user_token
        );
        this.$store.dispatch(
            "apidata/fetchAllTenants",
            localStorage.cs_user_token
        );
    },
    methods: {
        show(data) {
            this.users = data;
            console.log("clicked");
        },
    },
    computed: {
        stats() {
            return this.$store.getters["apidata/getUserCount"];
        },
    },
};
</script>
<style scoped>
.border:hover {
    cursor: pointer;
}
#staff_card:hover {
    cursor: pointer;
}
.status_row {
    display: flex;
    align-items: baseline;
    margin-left: 25px;
}

.Active {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: green;
}
.Inactive {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #c59220;
}
.Banned {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: red;
}
.dashboard_card--icon {
    height: 50px;
    width: 40px;
    object-fit: contain;
}
.dashboard_card--text {
    padding: 10px;
    margin: 10px;
    font-size: 1.5rem;
}
.card_first {
    -webkit-transform: perspective(600) rotateY(18deg);
    -moz-transform: perspective(600) rotateY(18deg);
    -ms-transform: perspective(600) rotateY(18deg);
    -o-transform: perspective(600) rotateY(18deg);
}
.card_last {
    -webkit-transform: perspective(600) rotateY(-18deg);
    -moz-transform: perspective(600) rotateY(-18deg);
    -ms-transform: perspective(600) rotateY(-18deg);
    -o-transform: perspective(600) rotateY(-18deg);
}
.card-img,
.card-img-top {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    margin: auto;
    margin-top: 25px;
    border: 1px solid #c59220;
}
.card-text {
    margin: auto;
    text-align: center;
}
.card_table {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
</style>
